// Screen Breakpoints
$screen-xxs:                 320px;
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;
$screen-mm-desktop:          ($screen-sm + 1);

$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$screen-xlg:                 1600px;

// Colors
$grey-blue: #6B748E;
$midnight-blue: #002F6C;
$dark-gray: #555;
$light-black: rgb(0,0,0,0.85);
$white: rgb(255, 255, 255);

@import '../../node_modules/sitecore-component-style/dist/main.css';
@import '../../node_modules/archetype-style/dist/main.css';

.app-root {
  background-color: $white;
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }
